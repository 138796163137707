import React from "react";
import CoverBanner from "./CoverBanner";
import { dataApiRoot } from "../constants.js";

export default class EmailSignup extends React.Component {
  constructor(props) {
    const customerSignupDisclaimerFiltered = props.state.systemcontent.filter(
      (DisplayContent) => {
        return DisplayContent.DetailLabel === "customer-signup-disclaimer";
      }
    );
    const customerSignupDisclaimer =
      customerSignupDisclaimerFiltered[0].DetailTextDescribe;

    super(props);
    this.state = {
      consentChecked: true,
      emailEntered: "",
      responseStatus: null,
      consentDisclaimer: customerSignupDisclaimer
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value =
      target.name === "consentChecked" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        CustomerFirstname: null,
        CustomerSurname: null,
        CustomerEmail: this.state.emailEntered,
        CustomerConsent: this.state.consentChecked,
        CustomerConsentContent: this.state.consentDisclaimer,
        LinkedPropertyID: [this.props.state.vanityPropertyId],
        CustomerConsentDaT:
          this.state.consentChecked === true ? new Date() : null
      })
    };

    this.setState({ responseStatus: "waiting" });

    fetch(`${dataApiRoot}usercustomer`, requestOptions)
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          consentChecked: true,
          emailEntered: "",
          responseStatus: data.status
        })
      );
    event.preventDefault();
    console.log(this.state.responseStatus);
  }

  render() {
    return (
      <>
        {(this.state.responseStatus === null ||
          this.state.responseStatus === "waiting") && (
          <>
            <CoverBanner
              {...this.props}
              BannerH1={"Special Offer"}
              BannerH2={"Save 10% when you book again"}
              BannerH3={
                "Re book directly with us for the best prices. Submit your email address to get an extra 10% off!"
              }
            />
            <div className="form-outer-wrapper">
              <form onSubmit={this.handleSubmit}>
                <div className="form-inner-wrapper">
                  <div className="form-textbox-and-label-wrapper">
                    <label className="form-textbox-label"></label>

                    <input
                      name="emailEntered"
                      className="form-textbox"
                      type="text"
                      value={this.state.emailEntered}
                      placeholder="Enter email address"
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="form-checkbox-and-label-outer-wrapper">
                    <label className="form-checkbox-and-label-inner-wrapper">
                      <input
                        name="consentChecked"
                        type="checkbox"
                        checked={this.state.consentChecked}
                        onChange={this.handleInputChange}
                      />

                      {this.state.consentDisclaimer}
                      <span className="form-checkmark"></span>
                    </label>
                  </div>

                  <input
                    className="form-submit-button"
                    type="submit"
                    value={
                      this.state.responseStatus === "waiting"
                        ? "Submitting..."
                        : "Submit"
                    }
                  />
                </div>
              </form>
            </div>
          </>
        )}
        {this.state.responseStatus === "success" && (
          <CoverBanner
            {...this.props}
            BannerH1={""}
            BannerH2={
              "Thank you for submitting your details. We'll be in touch shortly with details of how you can redeem your offer."
            }
            BannerH3={""}
          />
        )}
      </>
    );
  }
}
