import React from "react";

export default class WebLinkButton extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  // Redirect
  handleClick() {
    var webLinkUrl = this.props.WebLink;
    window.location.href = webLinkUrl;
  }

  render() {
    const { ButtonLabel } = this.props;
    return (
      <a
        href={`${this.props.WebLink}`}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="cta-button-round">
          <div className="cta-button-round-content-wrapper">
            <div className="cta-button-round-text-no-icon">{ButtonLabel}</div>
          </div>
        </button>
      </a>
    );
  }
}
