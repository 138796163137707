import React from "react";
import "../styles/PropertyHomeContactImage.css";

export default class PropertyHomeContactImage extends React.Component {
  render() {
    return (
      <div id="ph-contact-detail-image-wrapper">
        <img
          id="ph-contact-detail-image"
          src={this.props.ImageSource}
          alt={this.props.PropertyTitle}
        />
      </div>
    );
  }
}
