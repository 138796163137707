import React from "react";

function UserIcon(props) {
  return (
    <svg width={792} height={792} viewBox="0 0 792 792" {...props}>
      <path d="M568.037 404.712c31.68-42.743 50.713-97.515 50.713-157.212C618.75 110.806 519.032 0 396 0S173.25 110.806 173.25 247.5c0 59.697 19.033 114.469 50.713 157.212C165.8 424.834 123.75 479.481 123.75 544.5v99c0 82.021 66.479 148.5 148.5 148.5h247.5c82.021 0 148.5-66.479 148.5-148.5v-99c0-65.019-42.05-119.666-100.213-139.788zM222.75 247.5c0-109.346 77.566-198 173.25-198s173.25 88.654 173.25 198-77.566 198-173.25 198-173.25-88.654-173.25-198zm396 383.625c0 61.504-54.4 111.375-121.498 111.375H294.748c-67.097 0-121.498-49.871-121.498-111.375v-74.25c0-52.618 39.922-96.451 93.456-108.108C303.188 477.774 347.737 495 396 495s92.812-17.226 129.294-46.233c53.534 11.657 93.456 55.49 93.456 108.108v74.25z" />
    </svg>
  );
}

export default UserIcon;
