import React from "react";
import { googlePlacePhotoApiRootUrl, googleMapsApiKey } from "../constants.js";
import InfoDetailHeading from "./InfoDetailHeading";
import InfoDetailParagraph from "./InfoDetailParagraph";
import CertificationBanner from "./CertificationBanner";
import PDFViewerLinkButton from "./PDFViewerLinkButton";
import DirectionsLinkButton from "./DirectionsLinkButton";
import VideoPlayer from "./VideoPlayer";
import WebLinkButton from "./WebLinkButton";
import Header from "./Header";
import "../styles/InfoDetail.css";

export default class InfoDetailMulti extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    // Use final part of pathname to set Detail Label
    var pathname = window.location.pathname;
    var pathparams = pathname.substring(pathname.lastIndexOf("/") + 1);
    const lookupLabel = pathparams;

    // Use Detail label to lookup custom content
    const detailContent = this.props.state.customcontent.filter(
      (DisplayContent) => {
        return (
          DisplayContent.DetailLabel === lookupLabel &&
          (DisplayContent.DetailTextDescribe ||
            DisplayContent.DetailTextLocate ||
            DisplayContent.DetailTextOperate)
        );
      }
    );

    // Use Category label to lookup custom content
    const categoryContent = this.props.state.customcontent.filter(
      (DisplayContent) => {
        return (
          DisplayContent.CategoryLabel === lookupLabel &&
          (DisplayContent.DetailTextDescribe ||
            DisplayContent.DetailTextLocate ||
            DisplayContent.DetailTextOperate)
        );
      }
    );

    var displayContent = null;
    var categoryLabel = "";
    if (detailContent[0]) {
      displayContent = detailContent;
      categoryLabel = detailContent[0].CategoryLabel;
    }
    if (!detailContent[0]) {
      displayContent = categoryContent;
      categoryLabel = categoryContent[0].CategoryLabel;
    }

    // Set firstDetailLabel
    var firstDetailLabel = "";
    if (displayContent[0]) {
      firstDetailLabel = displayContent[0].DetailLabel;
    }

    // Find category
    const contentCategories = this.props.state.contentcategories.filter(
      (DisplayContent) => {
        return DisplayContent.CategoryLabel === categoryLabel;
      }
    );
    // Set category image
    var categoryImage = null;
    if (contentCategories[0]) {
      categoryImage = contentCategories[0].CategoryImage;
    }

    // Set Theme label
    var themeLabel = null;
    if (contentCategories[0]) {
      themeLabel = contentCategories[0].ThemeLabel;
    }

    // Display content on page
    return (
      <div className="info-detail-canvas">
        <Header {...this.props} />
        {displayContent.map((item) => (
          <div key={item._id}>
            {(item.DetailTextDescribe ||
              item.DetailTextLocate ||
              item.DetailTextOperate) && (
              <>
                <VideoPlayer
                  DetailVideo={item.DetailVideo}
                  DetailImage={
                    item.DetailImage
                      ? item.DetailImage
                      : item.GooglePhotoReference
                      ? `${googlePlacePhotoApiRootUrl}maxwidth=960&photoreference=${item.GooglePhotoReference}&key=${googleMapsApiKey}`
                      : null
                  }
                  CategoryImage={categoryImage}
                  DetailLabel={item.DetailLabel}
                  FirstDetailLabel={firstDetailLabel}
                  ThemeLabel={themeLabel}
                  SystemContent={this.props.state.systemcontent}
                />
                <InfoDetailHeading
                  DetailLabel={item.DetailLabel}
                  DetailTitle={item.DetailTitle}
                  SystemContent={this.props.state.systemcontent}
                />

                {item.DetailLabel === "our-cleaning-guarantee" &&
                  item.DetailBadge &&
                  item.DetailBadgeText && (
                    <>
                      <CertificationBanner
                        Image={item.DetailBadge}
                        AltText={item.DetailLabel}
                        Description={item.DetailBadgeText}
                        DetailLabel={item.DetailLabel}
                        SystemContent={this.props.state.systemcontent}
                      />
                      <div className="page-section-wrapper"> </div>
                    </>
                  )}

                <InfoDetailParagraph
                  DetailType={"describe"}
                  DetailLabel={item.DetailLabel}
                  DetailText={item.DetailTextDescribe}
                />

                <InfoDetailParagraph
                  DetailType={"locate"}
                  DetailLabel={item.DetailLabel}
                  DetailText={item.DetailTextLocate}
                />

                <InfoDetailParagraph
                  DetailType={"operate"}
                  DetailLabel={item.DetailLabel}
                  DetailText={item.DetailTextOperate}
                />

                <div className="cta-button-group-wrapper">
                  {item.DetailDocument && (
                    <PDFViewerLinkButton
                      {...this.props}
                      DetailDocument={item.DetailDocument}
                      DetailLabel={item.DetailLabel}
                      SystemContent={this.props.state.systemcontent}
                    />
                  )}

                  {item.WebLink && (
                    <WebLinkButton
                      {...this.props}
                      ButtonLabel="View website"
                      WebLink={item.WebLink}
                    />
                  )}

                  {item.GeoLocationLat && item.GeoLocationLng && (
                    <DirectionsLinkButton
                      {...this.props}
                      Latitude={item.GeoLocationLat}
                      Longitude={item.GeoLocationLng}
                      ButtonLabel="Get directions"
                    />
                  )}

                  {item.DetailLabel === "directions-info" && (
                    <DirectionsLinkButton
                      {...this.props}
                      Latitude={this.props.state.propertydata.GeoLocationLat}
                      Longitude={this.props.state.propertydata.GeoLocationLng}
                      ButtonLabel="Get directions"
                    />
                  )}
                </div>
              </>
            )}

            {!item.DetailTextDescribe &&
              !item.DetailTextLocate &&
              !item.DetailTextOperate && (
                <>
                  <VideoPlayer
                    DetailVideo={null}
                    DetailImage={categoryImage}
                    CategoryImage={categoryImage}
                    DetailLabel={item.DetailLabel}
                    FirstDetailLabel={firstDetailLabel}
                    SystemContent={this.props.state.systemcontent}
                  />
                  <InfoDetailHeading
                    DetailLabel={item.DetailLabel}
                    DetailTitle={item.DetailTitle}
                    SystemContent={this.props.state.systemcontent}
                  />
                  <InfoDetailParagraph
                    DetailType={"describe"}
                    DetailLabel={item.DetailLabel}
                    DetailText={
                      "No content has been added yet, please check back later"
                    }
                  />

                  {item.DetailLabel === "directions" && (
                    <>
                      <DirectionsLinkButton
                        {...this.props}
                        Latitude={this.props.state.propertydata.GeoLocationLat}
                        Longitude={this.props.state.propertydata.GeoLocationLng}
                        ButtonLabel="Get directions"
                      />
                    </>
                  )}
                </>
              )}
          </div>
        ))}
      </div>
    );
  }
}
