import React from "react";
import PropertyInfoMenuItem from "./PropertyInfoMenuItem";
import HeaderCompact from "./HeaderCompact";
import "../styles/PropertyInfo.css";

export default class PropertyInfoSubMenu extends React.Component {
  constructor() {
    super();
    this.handleBack = this.handleBack.bind(this);
  }

  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // Back button functionality
  handleBack() {
    this.props.history.goBack();
  }

  render() {
    // Use final part of pathname to set category label for filter
    var pathname = window.location.pathname;
    var pathparams = pathname.substring(pathname.lastIndexOf("/") + 1);
    const categorylabel = pathparams;

    // Retrieve current category to display as header
    const displaycontent = this.props.state.contentcategories.filter(
      (DisplayContent) => {
        return DisplayContent.CategoryLabel === categorylabel;
      }
    );
    const pagecategorytitle = displaycontent[0].CategoryTitle;

    // Filter out anything that doesnt have minimum text description
    const initialContentFilter = this.props.state.customcontent.filter(
      (item) => {
        return (
          item.DetailTextDescribe ||
          item.DetailTextLocate ||
          item.DetailTextOperate
        );
      }
    );

    // Return all categories found in the above
    const allCategories = initialContentFilter.map((a) => {
      return {
        categorylabel: a.CategoryLabel
      };
    });

    // Filter out empty and unwanted category labels from "Custom Content"
    const filteredCategories = allCategories.filter((DisplayContent) => {
      return (
        DisplayContent.categorylabel !== undefined &&
        DisplayContent.categorylabel !== null &&
        DisplayContent.categorylabel !== "cleaning-information" // temporary fix to filter out other cleaning content
      );
    });

    // Return unique categories found in the above
    var uniqueCategories = [];
    filteredCategories.forEach(function (item) {
      var i = uniqueCategories.findIndex(
        (x) => x.categorylabel === item.categorylabel
      );
      if (i <= -1) {
        uniqueCategories.push({
          categorylabel: item.categorylabel
        });
      }
    });

    // Filter out empty and unwanted category labels from "Content Categories"
    var contentCategories = this.props.state.contentcategories;
    var contentCategoriesFiltered = [];
    for (var arr in contentCategories) {
      for (var filter in uniqueCategories) {
        if (
          contentCategories[arr].ParentCategoryLabel === categorylabel &&
          contentCategories[arr].CategoryLabel ===
            uniqueCategories[filter].categorylabel
        ) {
          contentCategoriesFiltered.push({
            categorylabel: contentCategories[arr].CategoryLabel,
            categorytitle: contentCategories[arr].CategoryTitle,
            categoryimage: contentCategories[arr].CategoryImage,
            categoryshortdesc: contentCategories[arr].CategoryShortDesc,
            fullsubcategorylabel: `/${contentCategories[arr].CategoryLabel}`
          });
        }
      }
    }

    // Sort contentCategoriesFiltered by CategoryLabel
    var sortOrder = [
      // Arrival
      "welcome",
      "directions",
      "parking",
      "checking-in-and-out",
      // Place
      "internet",
      "temperature",
      "indoor-entertainment",
      "kitchen-and-dining",
      "bathing-and-showering",
      "childrens-equipment",
      "leisure-equipment",
      "washing-and-cleaning",
      "waste",
      "windows-and-doors",
      "outdoor-space",
      "floorplans",
      "inventory",
      // Local area
      "nearest",
      "attractions",
      "food-and-drink",
      "leisure-activity",
      "shopping",
      "amenity",
      "transport",
      // Safety
      "rules",
      "safety-features",
      "security",
      // Services
      "supplies",
      "onsite-services",
      // Cleaning
      "cleaning-guarantee"
    ];
    contentCategoriesFiltered.sort(function (a, b) {
      return (
        sortOrder.indexOf(a.categorylabel) - sortOrder.indexOf(b.categorylabel)
      );
    });

    return (
      <div className="canvas-with-compact-header-and-footer">
        <HeaderCompact {...this.props} MenuTitle={pagecategorytitle} />
        <div id="property-info-outer-wrapper">
          <div id="property-info-inner-wrapper">
            {contentCategoriesFiltered.map((item) => (
              <PropertyInfoMenuItem
                key={item.categorylabel}
                {...this.props}
                CategoryLabel={categorylabel}
                IconLabel={item.categorylabel}
                FullSubCategoryLabel={item.fullsubcategorylabel}
                CategoryImage={item.categoryimage}
                CategoryShortDesc={item.categoryshortdesc}
                CategoryTitle={item.categorytitle}
                PropertyID={this.props.state.vanityPropertyId}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
