import airportPin from "../pins/airport.png";
import atmPin from "../pins/atm.png";
import barPin from "../pins/bar.png";
// import beachPin from "../pins/beach.png";
import busStopPin from "../pins/bus.png";
import cafePin from "../pins/cafe.png";
import carRentalPin from "../pins/car_rental.png";
import defaultPin from "../pins/default.png";
import educationPin from "../pins/education.png";
// import evChargepointPin from "../pins/ev_chargepoint.png";
// import golfCoursePin from "../pins/golf_course.png";
import healthPin from "../pins/health.png";
import homePin from "../pins/home.png";
import hotelPin from "../pins/hotel.png";
import leisurePin from "../pins/leisure.png";
import manualTradePin from "../pins/trade_manual.png";
import parkPin from "../pins/park.png";
import parkingPin from "../pins/parking.png";
import petrolStationPin from "../pins/petrol_station.png";
// import portPin from "../pins/port.png";
import postboxPin from "../pins/postbox.png";
import professionalTradePin from "../pins/trade_professional.png";
import restaurantPin from "../pins/restaurant.png";
import shopPin from "../pins/shop.png";
// import swimmingPoolPin from "../pins/swimming_pool.png";
import taxiPin from "../pins/taxi.png";
import touristAttractionPin from "../pins/tourist_attraction.png";
import trainStationPin from "../pins/train_station.png";

const MapPins = {
  home: homePin,
  default: defaultPin,
  airport: airportPin,
  atm: atmPin,
  bar: barPin,
  night_club: barPin,
  bus_station: busStopPin,
  cafe: cafePin,
  campground: hotelPin,
  rv_park: hotelPin,
  parking: parkingPin,
  car_rental: carRentalPin,
  city_hall: defaultPin,
  courthouse: defaultPin,
  embassy: defaultPin,
  fire_station: defaultPin,
  library: defaultPin,
  local_government_office: defaultPin,
  police: defaultPin,
  primary_school: educationPin,
  school: educationPin,
  secondary_school: educationPin,
  university: educationPin,
  dentist: healthPin,
  doctor: healthPin,
  hospital: healthPin,
  pharmacy: healthPin,
  physiotherapist: healthPin,
  veterinary_care: healthPin,
  lodging: hotelPin,
  gym: leisurePin,
  bowling_alley: leisurePin,
  casino: leisurePin,
  movie_theater: leisurePin,
  park: parkPin,
  spa: leisurePin,
  stadium: leisurePin,
  gas_station: petrolStationPin,
  post_office: postboxPin,
  cemetery: defaultPin,
  church: defaultPin,
  hindu_temple: defaultPin,
  mosque: defaultPin,
  synagogue: defaultPin,
  meal_delivery: restaurantPin,
  meal_takeaway: restaurantPin,
  restaurant: restaurantPin,
  bakery: shopPin,
  bank: shopPin,
  beauty_salon: shopPin,
  bicycle_store: shopPin,
  book_store: shopPin,
  car_dealer: shopPin,
  car_repair: shopPin,
  car_wash: shopPin,
  clothing_store: shopPin,
  convenience_store: shopPin,
  department_store: shopPin,
  drugstore: shopPin,
  electronics_store: shopPin,
  florist: shopPin,
  funeral_home: shopPin,
  furniture_store: shopPin,
  hair_care: shopPin,
  hardware_store: shopPin,
  home_goods_store: shopPin,
  jewelry_store: shopPin,
  laundry: shopPin,
  liquor_store: shopPin,
  locksmith: shopPin,
  movie_rental: shopPin,
  moving_company: shopPin,
  pet_store: shopPin,
  real_estate_agency: shopPin,
  shoe_store: shopPin,
  shopping_mall: shopPin,
  storage: shopPin,
  store: shopPin,
  supermarket: shopPin,
  travel_agency: shopPin,
  taxi_stand: taxiPin,
  amusement_park: touristAttractionPin,
  aquarium: touristAttractionPin,
  art_gallery: touristAttractionPin,
  museum: touristAttractionPin,
  tourist_attraction: touristAttractionPin,
  zoo: touristAttractionPin,
  accounting: professionalTradePin,
  electrician: professionalTradePin,
  insurance_agency: professionalTradePin,
  lawyer: professionalTradePin,
  painter: manualTradePin,
  plumber: manualTradePin,
  roofing_contractor: manualTradePin,
  light_rail_station: trainStationPin,
  subway_station: trainStationPin,
  train_station: trainStationPin,
  transit_station: trainStationPin
};

export default MapPins;
