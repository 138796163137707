import React from "react";
import ReactMarkdown from "react-markdown";
import "../styles/InfoDetail.css";

export default class InfoDetailParagraph extends React.Component {
  render() {
    const { DetailText } = this.props;

    return (
      <div className="info-detail-wrapper">
        <ReactMarkdown className="info-detail-text" source={DetailText} />
      </div>
    );
  }
}
