import React from "react";
import "../styles/CertificationBanner.css";

const CertificationBanner = (props) => {
  const { Image, AltText, Description } = props;

  return (
    <>
      <div style={{ height: "15px" }}> </div>
      <div className="page-section-wrapper">
        <div id="cleaning-badge-and-text-outer-wrapper">
          <div id="cleaning-badge-and-text-inner-wrapper">
            <div id="ch-badge-image-wrapper">
              <img id="ch-badge-image" src={Image} alt={AltText} />
            </div>
            <div id="cleaning-badge-text">{Description}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CertificationBanner;
