import React from "react";

export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      didLoad: false
    };
  }

  onLoad = () => {
    this.setState({
      didLoad: true
    });
  };

  playPause = (e) => {
    e.preventDefault();
    var myVideo = document.getElementById("video1");
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  };

  render() {
    // Destructure props
    const {
      DetailVideo,
      DetailImage,
      CategoryImage,
      DetailLabel,
      FirstDetailLabel
    } = this.props;

    // Set Filenames so "null"s can be filtered out
    const detailImageFilename = DetailImage
      ? DetailImage.substring(DetailImage.lastIndexOf("/") + 1)
      : "";

    const VideoFilename = DetailVideo
      ? DetailVideo.substring(DetailVideo.lastIndexOf("/") + 1)
      : "";

    // Set Image source
    var ImageSource = null;
    if (DetailImage && detailImageFilename !== "null") {
      ImageSource = DetailImage;
    }
    if (
      (!DetailImage || detailImageFilename === "null") &&
      CategoryImage &&
      DetailLabel === FirstDetailLabel
    ) {
      ImageSource = CategoryImage;
    }

    const ImageType = ImageSource
      ? ImageSource.substring(ImageSource.lastIndexOf(".") + 1)
      : "";

    // Set variable classNames if loaded / not loaded
    var OuterWrapperStyle = "info-cover-image-outer-wrapper-loading";
    if (this.state.didLoad && ImageType === "svg") {
      OuterWrapperStyle = "info-cover-image-icon-outer-wrapper";
    }
    if (this.state.didLoad && ImageType !== "svg") {
      OuterWrapperStyle = "info-cover-image-outer-wrapper";
    }

    var InnerWrapperStyle = "info-cover-image-inner-wrapper-loading";
    if (this.state.didLoad && ImageType === "svg") {
      InnerWrapperStyle = "info-cover-image-icon-inner-wrapper";
    }
    if (this.state.didLoad && ImageType !== "svg") {
      InnerWrapperStyle = "info-cover-image-inner-wrapper";
    }

    var ImageStyle =
      "info-cover-image-loading info-cover-image-loading-animate";
    if (this.state.didLoad && ImageType === "svg") {
      ImageStyle = "info-cover-image-icon";
    }
    if (this.state.didLoad && ImageType !== "svg") {
      ImageStyle = "info-cover-image";
    }

    // If there is a custom video, display the video player
    if (DetailVideo && VideoFilename !== "null") {
      return (
        <div className="info-cover-image-outer-wrapper">
          <div className="info-cover-video-inner-wrapper">
            <div className="info-cover-video">
              <video
                className="info-cover-video"
                id="video1"
                controls
                src={DetailVideo}
                type="video/mp4"
                onClick={this.playPause}
              >
                This browser does not support video. Try Chrome or Safari
                instead.
              </video>
            </div>
          </div>
        </div>
      );
    }

    // If there is no custom video, and a custom image, display the image
    if ((!DetailVideo || VideoFilename === "null") && ImageSource) {
      return (
        <div className={OuterWrapperStyle}>
          <div className={InnerWrapperStyle}>
            <img
              className={ImageStyle}
              src={ImageSource}
              alt={DetailLabel}
              onLoad={this.onLoad}
            />
          </div>
        </div>
      );
    }

    // If there is no image or video, display nothing
    return <></>;
  }
}
