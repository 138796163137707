import React from "react";

function DocumentIcon(props) {
  return (
    <svg
      width={753.352}
      height={753.352}
      viewBox="0 0 753.352 753.352"
      {...props}
    >
      <path d="M517.906 517.967H235.445c-12.993 0-23.538 10.546-23.538 23.539s10.545 23.538 23.538 23.538h282.461c12.993 0 23.539-10.545 23.539-23.538s-10.546-23.539-23.539-23.539zM494.368.121v.683c-3.036 0-15.559-1.177-47.077-.683H164.83c-51.997 0-94.154 42.157-94.154 94.154v564.923c0 51.996 42.157 94.153 94.154 94.153h423.692c51.997 0 94.154-42.157 94.154-94.153v-470.77L494.368.121zm141.231 659.077c0 25.986-21.066 47.077-47.077 47.077H164.83c-26.01 0-47.077-21.091-47.077-47.077V94.275c0-26.01 21.067-47.077 47.077-47.077h281.802c-.424 56.375.659 94.154.659 94.154 0 51.997 42.157 94.154 94.154 94.154h94.153v423.692zm-94.154-470.769c-26.011 0-47.077-21.09-47.077-47.077V47.198l141.23 141.23h-94.153zm-23.539 188.307H235.445c-12.993 0-23.538 10.546-23.538 23.539s10.545 23.538 23.538 23.538h282.461c12.993 0 23.539-10.545 23.539-23.538s-10.546-23.539-23.539-23.539z" />
    </svg>
  );
}

export default DocumentIcon;
