import React from "react";
import DocumentIcon from "../icons/DocumentIcon";

export default class PDFViewerLinkButton extends React.Component {
  constructor(props) {
    super(props);

    const { DetailDocument } = this.props;
    const DocumentFilename = DetailDocument
      ? DetailDocument.substring(DetailDocument.lastIndexOf("/") + 1)
      : "";
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      documentFilename: DocumentFilename
    };
  }

  // Redirect to a page created from button id and property id upon button click
  handleClick() {
    this.props.history.push({
      pathname: "/" + this.props.state.vanityPropertyId + "/docviewer",
      state: { DetailDocument: this.props.DetailDocument }
    });
  }

  render() {
    if (this.state.documentFilename && this.state.documentFilename !== "null") {
      return (
        <button className="cta-button-round" onClick={this.handleClick}>
          <div className="cta-button-round-content-wrapper">
            <div className="cta-button-round-icon-icon-wrapper">
              <DocumentIcon className="cta-button-round-icon" />
              <div className="cta-button-round-text"> View PDF </div>
            </div>
          </div>
        </button>
      );
    }
    return <></>;
  }
}
