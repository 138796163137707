import React from "react";

function ListMenuIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={612}
      height={612}
      viewBox="0 0 612 612"
      {...props}
    >
      <path d="M535.5 0h-459C34.521 0 0 37.657 0 79.656v456.303C0 577.957 34.023 612 76.003 612H532c41.979 0 80-34.502 80-76.5v-459C612 34.501 577.479 0 535.5 0zm38.25 535.5c0 20.999-20.77 38.479-41.75 38.479H76.003c-20.98 0-38.001-17.021-38.001-38.021V79.656c0-21 17.519-41.406 38.499-41.406h459c20.98 0 38.25 17.251 38.25 38.25v459zM459 382.5H153c-10.557 0-19.125 8.568-19.125 19.125S142.443 420.75 153 420.75h306c10.557 0 19.125-8.568 19.125-19.125S469.557 382.5 459 382.5zm0-114.75H153c-10.557 0-19.125 8.568-19.125 19.125S142.443 306 153 306h306c10.557 0 19.125-8.568 19.125-19.125S469.557 267.75 459 267.75zM459 153H153c-10.557 0-19.125 8.568-19.125 19.125S142.443 191.25 153 191.25h306c10.557 0 19.125-8.568 19.125-19.125S469.557 153 459 153z" />
    </svg>
  );
}

export default ListMenuIcon;
