import React, { Component } from "react";
import GoogleMap from "./GoogleMap";
import { googlePlacePhotoApiRootUrl, googleMapsApiKey } from "../constants.js";
import MapPins from "../pins/MapPins.js";
import "../styles/PropertyMap.css";

export default class PropertyMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // Filter correct content for places
    const customPlaces = this.props.state.customcontent.filter(
      (DisplayContent) => {
        return (
          DisplayContent.ContentType === "place" &&
          DisplayContent.GeoLocationLat &&
          DisplayContent.GeoLocationLng
        );
      }
    );

    // Create customPlaces entry for this property
    const thisProperty = {
      DetailImage: this.props.state.propertydata.PropertyCoverImage,
      DetailTitle: this.props.state.propertydata.PropertyTitle,
      GeoLocationFormattedAddress: this.props.state.propertydata
        .GeoLocationFormattedAddress,
      GeoLocationLat: this.props.state.propertydata.GeoLocationLat,
      GeoLocationLng: this.props.state.propertydata.GeoLocationLng,
      DetailTextDescribe: this.props.state.propertydata.PropertyDisplayAddress,
      PlaceTypeLabel: "home"
    };
    // Push this property into the customPlaces list
    customPlaces.push(thisProperty);

    // Create variables for Infowindow button
    var propertyID = this.props.state.propertydata.PropertyID;
    var historyPush = this.props.history.push;

    return (
      <GoogleMap
        id="myMap"
        options={{
          center: {
            lat: this.props.state.propertydata.GeoLocationLat,
            lng: this.props.state.propertydata.GeoLocationLng
          },
          zoom: 12,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: false,
          gestureHandling: "greedy",
          styles: [
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "poi",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "road",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "transit",
              stylers: [
                {
                  visibility: "on"
                }
              ]
            }
          ]
        }}
        onMapLoad={(map) => {
          for (let i = 0; i < customPlaces.length; i++) {
            var place = customPlaces[i];
            var placeMarker = new window.google.maps.Marker({
              position: {
                lat: place.GeoLocationLat,
                lng: place.GeoLocationLng
              },
              map: map,
              icon: {
                url: MapPins[place.PlaceTypeLabel]
                  ? MapPins[place.PlaceTypeLabel]
                  : MapPins.default,
                scaledSize: new window.google.maps.Size(40, 54.5)
              },
              optimized: false
            });

            // On button click in InfoWindow, redirect page to Google Maps
            function redirectToGoogleMaps(place) {
              var googleMapsUrl =
                "http://maps.google.com/maps/place/" +
                place.GeoLocationLat +
                "," +
                place.GeoLocationLng;
              window.location = googleMapsUrl;
            }

            let infowindow = new window.google.maps.InfoWindow();

            window.google.maps.event.addListener(
              placeMarker,
              "click",
              (function (placeMarker, place) {
                var infowindowWrapper = document.createElement("div"),
                  button;
                infowindowWrapper.className = "map-infowindow";

                if (place.GooglePhotoReference) {
                  var infowindowImage = infowindowWrapper.appendChild(
                    document.createElement("IMG")
                  );
                  infowindowImage.className = "infowindow-image";
                  infowindowImage.setAttribute(
                    "src",
                    googlePlacePhotoApiRootUrl +
                      "maxwidth=150&photoreference=" +
                      place.GooglePhotoReference +
                      "&key=" +
                      googleMapsApiKey
                  );
                  infowindowImage.setAttribute("alt", place.DetailTitle);
                }
                var infowindowHeader = infowindowWrapper.appendChild(
                  document.createElement("div")
                );
                infowindowHeader.className = "infowindow-header";
                infowindowHeader.innerHTML = place.DetailTitle;

                var infowindowText = infowindowWrapper.appendChild(
                  document.createElement("div")
                );
                infowindowText.className = "infowindow-text";
                infowindowText.innerHTML = place.DetailTextDescribe
                  ? place.DetailTextDescribe
                  : place.GeoLocationFormattedAddress
                  ? place.GeoLocationFormattedAddress
                  : "";

                var infowindowButtonOuterWrapper = infowindowWrapper.appendChild(
                  document.createElement("div")
                );
                infowindowButtonOuterWrapper.className =
                  "infowindow-button-outer-wrapper";

                var infowindowButtonInnerWrapper = infowindowButtonOuterWrapper.appendChild(
                  document.createElement("div")
                );
                infowindowButtonInnerWrapper.className =
                  "infowindow-button-inner-wrapper";

                if (
                  place.ContentType === "place" &&
                  (place.DetailTextDescribe ||
                    place.DetailTextLocate ||
                    place.DetailTextOperate)
                ) {
                  button = infowindowButtonInnerWrapper.appendChild(
                    document.createElement("input")
                  );
                  button.type = "button";
                  button.className = "infowindow-button-primary-round";
                  button.value = "Read more";
                  window.google.maps.event.addDomListener(
                    button,
                    "click",
                    function () {
                      historyPush({
                        pathname:
                          "/" +
                          `${propertyID}` +
                          "/nearby" +
                          "/" +
                          `${place.CategoryLabel}`.toLowerCase() +
                          "/" +
                          `${place.DetailLabel}`
                            .toLowerCase()
                            .replace(/\s+/g, ""),
                        state: { PlaceID: place._id }
                      });
                    }
                  );
                }

                button = infowindowButtonInnerWrapper.appendChild(
                  document.createElement("input")
                );
                button.type = "button";
                button.className = "infowindow-button-secondary-round";
                button.value = "Get directions";
                window.google.maps.event.addDomListener(
                  button,
                  "click",
                  function () {
                    redirectToGoogleMaps(place);
                  }
                );

                return function () {
                  infowindow.setContent(infowindowWrapper);
                  infowindow.open(map, placeMarker);
                };
              })(placeMarker, place)
            );
          }
        }}
      />
    );
  }
}
