import React from "react";
import "../styles/AppHomepage.css";
import logo from "../images/airguide-logo-tm-white.png";
import EnterIdForm from "./EnterIdForm";

export default class AppHomepage extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="homepage-canvas">
        <div>
          <img src={logo} alt="logo" id="homepage-logo-wrapper" />
        </div>
        <EnterIdForm />
      </div>
    );
  }
}
