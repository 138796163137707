import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";
import ChevronRightIcon from "../icons/ChevronRightIcon";
import HeaderCompact from "./HeaderCompact";
import "../styles/PDFViewer.css";

export default class PDFViewer extends Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  state = {
    numPages: null,
    pageNumber: 1
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handlePrevious() {
    this.setState({ pageNumber: this.state.pageNumber - 1 });
  }

  handleNext() {
    this.setState({ pageNumber: this.state.pageNumber + 1 });
  }

  render() {
    // Set width of pdf page based on window size upto max 960px
    var windowWidth = window.innerWidth;
    var setWidth = "";

    if (windowWidth > 960) {
      setWidth = 960;
    } else if (windowWidth < 960) {
      setWidth = windowWidth;
    }

    // Disable pagination previous button if page number is 1
    var disablePreviousButton = true;
    if (this.state.pageNumber === 1) {
      disablePreviousButton = true;
    } else if (this.state.pageNumber > 1) {
      disablePreviousButton = false;
    }

    // Disable pagination next button if page number is maximum
    var disableNextButton = true;
    if (this.state.pageNumber === this.state.numPages) {
      disableNextButton = true;
    } else if (this.state.pageNumber < this.state.numPages) {
      disableNextButton = false;
    }

    const { pageNumber, numPages } = this.state;
    const DetailDocument = this.props.location.state.DetailDocument;

    // Set Filenames so "null"s can be filtered out
    const DocumentFilename = DetailDocument
      ? DetailDocument.substring(DetailDocument.lastIndexOf("/") + 1)
      : "";

    if (DetailDocument && DocumentFilename !== "null") {
      return (
        <div>
          <div className="canvas-with-compact-header-and-footer">
            <HeaderCompact {...this.props} MenuTitle="PDF Document View" />
            <div className="document-viewer-container">
              <Document
                className="document-viewer-document"
                file={DetailDocument}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <div className="document-viewer-page-wrapper">
                  <Page width={setWidth} pageNumber={pageNumber} />
                </div>
              </Document>
            </div>
          </div>

          <div className="pagination-wrapper-outer">
            <div className="pagination-wrapper-inner">
              <button
                className="pagination-button-round"
                type="button"
                disabled={disablePreviousButton}
                onClick={this.handlePrevious}
              >
                <ChevronLeftIcon className="pagination-button-round-icon" />
              </button>
              <p className="pagination-text">
                Page {pageNumber} of {numPages}
              </p>
              <button
                className="pagination-button-round"
                type="button"
                disabled={disableNextButton}
                onClick={this.handleNext}
              >
                <ChevronRightIcon className="pagination-button-round-icon" />
              </button>
            </div>
          </div>
        </div>
      );
    }
    // If there is no documeny
    return <></>;
  }
}
