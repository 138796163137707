// Set liveStatus "true" for live data or "false" for test data
// const liveStatus = false;

// Workflow API
export const workflowApiRoot = "https://airguide.bubbleapps.io/version-test/api/1.1/wf/";

// Data API
export const dataApiRoot = "https://airguide.bubbleapps.io/version-test/api/1.1/obj/";

// Root URL for live End-user App
export const liveRootUrl = "https://air.guide/";

// Google Place Photo Service
export const googlePlacePhotoApiRootUrl =
  "https://maps.googleapis.com/maps/api/place/photo?";

// Google Maps API Key
export const googleMapsApiKey = "AIzaSyDswx22sUIpbmrjqhvhID03u-lJllX_wMY";

export const googleAnalyticsId = `${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`;

// System Id to identify application type
export const systemId = "100000";
