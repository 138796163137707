import React from "react";

function EmailIcon(props) {
  return (
    <svg width={612} height={612} viewBox="0 0 612 612" {...props}>
      <path d="M535.5 76.5h-459C34.253 76.5 0 110.753 0 153v306c0 42.247 34.253 76.5 76.5 76.5h459c42.247 0 76.5-34.253 76.5-76.5V153c0-42.247-34.253-76.5-76.5-76.5zm19.125 38.25L306 306 57.375 114.75h497.25zM38.25 459V153l172.125 133.875-170.308 183.18c-1.071-3.52-1.817-7.172-1.817-11.055zm29.739 37.236L240.592 312.33 306 361.749l62.596-49.744 175.415 184.212c-2.754.632-473.268.632-476.022.019zm503.945-26.163L401.625 286.875 573.75 153v306c0 3.883-.746 7.535-1.816 11.073z" />
    </svg>
  );
}

export default EmailIcon;
