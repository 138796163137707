import React from "react";
import "../styles/CoverBanner.css";
export default class CoverBanner extends React.Component {
  render() {
    const { BannerH1, BannerH2, BannerH3 } = this.props;

    return (
      <div className="cover-banner-outer-wrapper">
        <div className="cover-banner-inner-wrapper">
          <div className="cover-banner">
            <div className="cover-banner-h1"> {BannerH1} </div>
            <div className="cover-banner-h2"> {BannerH2} </div>
            <div className="cover-banner-h3"> {BannerH3} </div>
          </div>
        </div>
      </div>
    );
  }
}
