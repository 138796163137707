import React from "react";
import "../styles/EnterIdForm.css";

export default class EnterID extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    var urlWithID = window.location.href + this.state.value;
    window.location.href = urlWithID;
    event.preventDefault();
  }

  render() {
    return (
      <div id="id-submission-form">
        <form onSubmit={this.handleSubmit}>
          <div id="id-submission-form-label">
            <label>Enter ID:</label>
          </div>

          <div id="id-submission-form-input-and-button-wrapper">
            <input
              id="id-submission-form-input"
              type="text"
              value={this.state.value}
              onChange={this.handleChange}
            />

            <input id="id-submission-form-button" type="submit" value="Go" />
          </div>
        </form>
      </div>
    );
  }
}
