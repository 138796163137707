import React from "react";

function PhoneIcon(props) {
  return (
    <svg
      width={611.989}
      height={611.989}
      viewBox="0 0 611.989 611.989"
      {...props}
    >
      <path d="M593.742 68.874l-56.656-56.499c-15.65-15.591-41.006-15.591-56.656 0l-84.985 112.998c-12.992 19.135-15.65 40.888 0 56.499l34.667 34.568c-25.71 34.451-56.577 72.031-91.323 106.68-39.274 39.175-82.997 74.807-122.29 104.041l-33.506-33.428c-15.65-15.61-37.462-12.953-56.656 0L13.023 478.482c-18.997 13.091-15.65 40.907 0 56.499l56.656 56.499c31.281 31.202 71.992 21.201 113.313 0 0 0 125.066-70.023 232.139-176.801 100.714-100.438 178.611-232.828 178.611-232.828 16.241-44.509 31.281-81.755 0-112.977zm-28.328 84.768c-33.467 63.566-107.762 161.446-169.97 225.997-71.716 77.583-240.8 183.632-240.8 183.632-13.052 6.83-49.274 7.362-59.235-2.579l-36.045-35.947c-7.441-12.441-10.788-26.871 0-35.946l72.11-53.94c12.205-8.248 26.104-9.921 36.045 0l44.432 44.294c10.473-7.362 177.352-116.738 270.191-267.297l-45.514-45.376c-9.961-9.922-8.269-23.781 0-35.947l54.077-71.913c9.962-13.269 26.104-9.922 36.065 0l36.045 35.947c14.155 13.266 10.08 44.192 2.599 59.075z" />
    </svg>
  );
}

export default PhoneIcon;
