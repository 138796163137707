import React from "react";
import HeaderBackButton from "./HeaderBackButton";
import HeaderCloseButton from "./HeaderCloseButton";
// import GeneratePDFButton from "./GeneratePDFButton";
// import SearchButton from "./SearchButton";
import "../styles/Header.css";

export default class Header extends React.Component {
  render() {
    if (
      this.props.match.path ===
        "/:vanityPropertyId/info/:categorylabel/:subcategorylabel" ||
      this.props.match.path === "/:vanityPropertyId/info/cleaning" ||
      this.props.match.path ===
        "/:vanityPropertyId/info/:categorylabel/:subcategorylabel/:detaillabel" ||
      this.props.match.path ===
        "/:vanityPropertyId/info/:categorylabel/:detaillabel" ||
      this.props.match.path ===
        "/:vanityPropertyId/featured/:categorylabel/:detaillabel" ||
      this.props.match.path === "/:vanityPropertyId/offers/other-places/*" ||
      this.props.match.path === "/:vanityPropertyId/offers/local/details/*"
    ) {
      return (
        <header className="header-outer">
          <div className="header-inner">
            <HeaderBackButton {...this.props} state={this.state} />
          </div>
        </header>
      );
    }

    if (this.props.match.path === "/:vanityPropertyId/nearby/*") {
      return (
        <header className="header-outer">
          <div className="header-inner">
            <HeaderCloseButton {...this.props} state={this.state} />
          </div>
        </header>
      );
    }

    return <div> </div>;
  }
}
