import React from "react";

function CrossIcon(props) {
  return (
    <svg viewBox="0 0 612 612" {...props}>
      <path d="M72.9 582.3L582.3 72.9c15.6-15.6 18.6-38 6.7-49.9-11.9-11.9-34.3-9-49.9 6.7L29.7 539.1C14 554.7 11.1 577.1 23 589c11.9 11.9 34.3 9 49.9-6.7z" />
      <path d="M581.2 539.7L71.8 30.3c-15.6-15.6-38-18.6-49.9-6.7-11.9 11.9-9 34.3 6.7 49.9L538 582.9c15.6 15.6 38 18.6 49.9 6.7 11.9-12 8.9-34.3-6.7-49.9z" />
    </svg>
  );
}

export default CrossIcon;
