import React from "react";
import HeaderBackButton from "./HeaderBackButton";
// import HeaderCloseButton from "./HeaderCloseButton";
// import GeneratePDFButton from "./GeneratePDFButton";
// import SearchButton from "./SearchButton";
import "../styles/HeaderCompact.css";

export default class HeaderCompact extends React.Component {
  render() {
    if (
      this.props.match.path === "/:vanityPropertyId/info" ||
      this.props.match.path === "/:vanityPropertyId/info/:categorylabel"
    ) {
      return (
        <header className="header-compact-outer">
          <div className="header-compact-inner">
            <HeaderBackButton {...this.props} state={this.state} />
            <div className="header-compact-text"> {this.props.MenuTitle} </div>
            <div className="header-compact-button"> </div>
          </div>
        </header>
      );
    }

    if (
      this.props.match.path === "/:vanityPropertyId/latestcleaningreport/*" ||
      this.props.match.path === "/:vanityPropertyId/cleaningreport/*" ||
      this.props.match.path === "/:vanityPropertyId/docviewer"
    ) {
      return (
        <header className="header-compact-outer">
          <div className="header-compact-inner">
            <HeaderBackButton {...this.props} state={this.state} />
            <div className="header-compact-text"> {this.props.MenuTitle} </div>
            <div className="header-compact-button"> </div>
          </div>
        </header>
      );
    }
  }
}
