import React from "react";
import PropertyInfoMenuImage from "./PropertyInfoMenuImage";
import ChevronRightIcon from "../icons/ChevronRightIcon";
import "../styles/InfoListItem.css";

export default class InfoListItem extends React.Component {
  constructor() {
    super();

    // Set defualt state
    this.state = {
      fullsubcategorylabel: ""
    };

    this.handleClick = this.handleClick.bind(this);
  }

  // Redirect to a page created from button id and property id upon button click
  handleClick = (e) => {
    this.props.history.push(
      "/" +
        this.props.PropertyID +
        "/" +
        this.props.TopLevelPath +
        "/" +
        this.props.CategoryLabel +
        "/" +
        this.props.DetailLabel
    );
  };

  render() {
    const { CategoryLabel, DetailImage, AltText } = this.props;

    // Find category
    const contentCategories = this.props.state.contentcategories.filter(
      (DisplayContent) => {
        return DisplayContent.CategoryLabel === CategoryLabel;
      }
    );
    // Set category image
    var CategoryImage = null;
    if (contentCategories[0]) {
      CategoryImage = contentCategories[0].CategoryImage;
    }

    return (
      <div className="info-list-item" onClick={this.handleClick}>
        <div className="info-list-item-image-and-content">
          <PropertyInfoMenuImage
            DetailImage={DetailImage}
            CategoryImage={CategoryImage}
            AltText={AltText}
          />
          <div>
            <div className="info-list-item-heading">{this.props.Heading}</div>
            <div className="info-list-item-subheading">
              {this.props.SubHeading}
            </div>
          </div>
        </div>
        <div className="info-list-item-chevron-icon-wrapper">
          <ChevronRightIcon className="info-list-item-chevron-icon" />
        </div>
      </div>
    );
  }
}
