import React from "react";
import { googlePlacePhotoApiRootUrl, googleMapsApiKey } from "../constants.js";
import Header from "./Header";
import InfoDetailHeading from "./InfoDetailHeading";
import InfoDetailParagraph from "./InfoDetailParagraph";
import WebLinkButton from "./WebLinkButton";
import DirectionsLinkButton from "./DirectionsLinkButton";
import VideoPlayer from "./VideoPlayer";

export default class InfoDetailPlace extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    // Use passed props to set placeid
    const placeid = this.props.history.location.state.PlaceID;

    // Filter shared place content by place id
    const placeContentFiltered = this.props.state.customcontent.filter(
      (DisplayContent) => {
        return DisplayContent._id === placeid;
      }
    );
    const placeContent = placeContentFiltered[0];

    const firstDetailLabel = placeContent.DetailLabel;

    // Find category
    const contentCategories = this.props.state.contentcategories.filter(
      (DisplayContent) => {
        return DisplayContent.CategoryLabel === placeContent.CategoryLabel;
      }
    );

    // Set category image
    var categoryImage = null;
    if (contentCategories[0]) {
      categoryImage = contentCategories[0].CategoryImage;
    }

    // Display content on page
    return (
      <div className="info-detail-canvas">
        <Header {...this.props} />
        <VideoPlayer
          DetailVideo={placeContent.DetailVideo}
          DetailImage={
            placeContent.GooglePhotoReference
              ? `${googlePlacePhotoApiRootUrl}maxwidth=960&photoreference=${placeContent.GooglePhotoReference}&key=${googleMapsApiKey}`
              : null
          }
          CategoryImage={categoryImage}
          DetailLabel={placeContent.DetailLabel}
          FirstDetailLabel={firstDetailLabel}
          SystemContent={this.props.state.systemcontent}
        />

        <InfoDetailHeading
          DetailLabel={placeContent.DetailLabel}
          DetailTitle={placeContent.DetailTitle}
          SystemContent={this.props.state.systemcontent}
        />

        <InfoDetailParagraph
          DetailType={"describe"}
          DetailLabel={placeContent.DetailLabel}
          DetailText={placeContent.DetailTextDescribe}
        />

        <InfoDetailParagraph
          DetailType={"locate"}
          DetailLabel={placeContent.DetailLabel}
          DetailText={placeContent.DetailTextLocate}
        />

        <InfoDetailParagraph
          DetailType={"operate"}
          DetailLabel={placeContent.DetailLabel}
          DetailText={placeContent.DetailTextOperate}
        />

        <div className="cta-button-group-wrapper">
          {placeContent.WebLink && (
            <WebLinkButton
              {...this.props}
              ButtonLabel="View website"
              WebLink={placeContent.WebLink}
            />
          )}

          {placeContent.GeoLocationLat && placeContent.GeoLocationLng && (
            <DirectionsLinkButton
              {...this.props}
              ButtonLabel="Get directions"
              Latitude={placeContent.GeoLocationLat}
              Longitude={placeContent.GeoLocationLng}
            />
          )}
        </div>
      </div>
    );
  }
}
