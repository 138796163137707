import React from "react";
import "../styles/InfoDetail.css";

export default class InfoDetailHeading extends React.Component {
  render() {
    const { DetailTitle, DetailLabel, SystemContent } = this.props;

    // Use Detail label to lookup default content
    const defaultContentFiltered = SystemContent.filter((DisplayContent) => {
      return DisplayContent.DetailLabel === DetailLabel;
    });

    // Set defaulTitle to avoid errors if none exists
    var defaultTitle = "";
    if (defaultContentFiltered[0]) {
      defaultTitle = defaultContentFiltered[0].DetailTitle;
    }

    return (
      <h1 className="info-detail-h1">
        {DetailTitle ? DetailTitle : defaultTitle}
      </h1>
    );
  }
}
