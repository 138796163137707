import React from "react";

export default class InfoDetailLinkButton extends React.Component {
  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
  }

  // Redirect to a page created from ids
  handleClick = (e) => {
    this.props.history.push(
      "/" +
        this.props.PropertyID +
        "/" +
        this.props.TopLevelPath +
        "/" +
        this.props.CategoryLabel +
        "/" +
        this.props.DetailLabel
    );
  };

  render() {
    if (
      this.props.DetailLabel === undefined ||
      this.props.DetailLabel === null
    ) {
      return <></>;
    }

    return (
      <button className="cta-button-round" onClick={this.handleClick}>
        <div className="cta-button-round-content-wrapper">
          <div className="cta-button-round-text-no-icon">
            {this.props.Heading}
          </div>
        </div>
      </button>
    );
  }
}
