import React from "react";
import PropertyHomeContactImage from "./PropertyHomeContactImage";
import UserIcon from "../icons/UserIcon";
import PhoneIcon from "../icons/PhoneIcon";
import EmailIcon from "../icons/EmailIcon";
import "../styles/PropertyHomeContactDetails.css";

export default class PropertyHomeContactDetails extends React.Component {
  render() {
    return (
      <div className="ph-contact-detail-outer-wrapper">
        <div className="ph-contact-detail-mid-wrapper">
          <PropertyHomeContactImage
            ImageSource={this.props.state.propertymgrdata.PropertyMgrCoverImage}
          />

          <div>
            <div id="ph-contact-details-name-wrapper">
              <div className="ph-contact-detail-wrapper">
                <div className="ph-contact-detail-icon-wrapper">
                  <UserIcon id="ph-contact-detail-user-icon" />
                </div>

                <div className="ph-contact-detail-text-wrapper">
                  <p id="ph-contact-detail-name">
                    {this.props.state.propertymgrdata.PropertyMgrFirstname}{" "}
                    {this.props.state.propertymgrdata.PropertyMgrLastname}
                  </p>
                </div>
              </div>
            </div>
            <div id="ph-contact-details-call-wrapper">
              <a
                href={`tel:${this.props.state.propertymgrdata.PropertyMgrTel}`}
                target="_self"
                className="ph-contact-detail-wrapper"
              >
                <div className="ph-contact-detail-icon-wrapper">
                  <PhoneIcon id="ph-contact-detail-phone-icon" />
                </div>

                <div className="ph-contact-detail-text-wrapper">
                  <p className="ph-contact-detail-text">
                    {this.props.state.propertymgrdata.PropertyMgrTel}
                  </p>
                </div>
              </a>
            </div>

            <div id="ph-contact-details-email-wrapper">
              <a
                href={`mailto:${this.props.state.propertymgrdata.PropertyMgrEmail}`}
                target="_self"
                className="ph-contact-detail-wrapper"
              >
                <div className="contact-detail-icon-wrapper">
                  <EmailIcon id="ph-contact-detail-email-icon" />
                </div>
                <div className="ph-contact-detail-text-wrapper">
                  <p className="ph-contact-detail-text">
                    {this.props.state.propertymgrdata.PropertyMgrEmail}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
