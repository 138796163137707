import React from "react";
import EmailSignup from "./EmailSignup";
import InfoListItem from "./InfoListItem";
import "../styles/Forms.css";

export default class OffersHome extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const relatedProperties = this.props.state.relatedproperties;

    // Filter correct content for "Further Information" section
    const localOffers = this.props.state.customcontent.filter(
      (DisplayContent) => {
        return DisplayContent.ContentType === "special-offer";
      }
    );

    // Sort the above alphabetically
    localOffers.sort(function (a, b) {
      var keyA = a.DetailTitle,
        keyB = b.DetailTitle;
      // Compare the 2 values
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    return (
      <>
        <div className="canvas-with-header-overlap-and-footer">
          <EmailSignup {...this.props} />
        </div>

        {(relatedProperties === !undefined || relatedProperties.length > 0) && (
          <div className="page-section-wrapper">
            <div className="list-section-outer-wrapper">
              <div className="list-section-heading"> Our Other Places</div>

              <div className="list-section-inner-wrapper">
                {relatedProperties.map((item) => (
                  <InfoListItem
                    {...this.props}
                    key={item._id}
                    Heading={item.MarketingTitle}
                    SubHeading={item.MarketingStrapline}
                    CategoryLabel={"other-places"}
                    SubCategoryLabel={"details"}
                    DetailLabel={item.PropertyID}
                    Image={item.PropertyCoverImage}
                    PropertyID={this.props.state.vanityPropertyId}
                    TopLevelPath="offers"
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {(localOffers === !undefined || localOffers.length > 0) && (
          <div className="page-section-wrapper">
            <div className="list-section-outer-wrapper">
              <div className="list-section-heading"> Local Offers</div>

              <div className="list-section-inner-wrapper">
                {localOffers.map((item) => (
                  <InfoListItem
                    {...this.props}
                    key={item._id}
                    Heading={item.DetailTitle}
                    SubHeading={null}
                    CategoryLabel={"local"}
                    SubCategoryLabel={"details"}
                    DetailLabel={item.DetailLabel}
                    Image={item.DetailImage}
                    PropertyID={this.props.state.vanityPropertyId}
                    TopLevelPath="offers"
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
