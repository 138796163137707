import React from "react";
import PropertyInfoMenuItem from "./PropertyInfoMenuItem";
import HeaderCompact from "./HeaderCompact";
import "../styles/PropertyInfo.css";

export default class PropertyInfoMainMenu extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    console.log("this.state", this.props.state);
    // Filter out anything that doesnt have minimum text description
    const initialContentFilter = this.props.state.customcontent.filter(
      (item) => {
        return (
          item.DetailTextDescribe ||
          item.DetailTextLocate ||
          item.DetailTextOperate
        );
      }
    );

    // Return all categories found in "Custom Content" database
    const allCustomCategories = initialContentFilter.map((item) => {
      return {
        categorylabel: item.CategoryLabel
      };
    });
    console.log(allCustomCategories);
    // Filter out empty and unwanted category labels from "Custom Content"
    const filteredCustomCategories = allCustomCategories.filter((item) => {
      return (
        item.categorylabel !== undefined &&
        item.categorylabel !== null &&
        item.categorylabel !== "cleaning-information" // temporary fix to filter out other cleaning content
      );
    });

    // Return all data from "Content Categories" database
    const allContentCategories = this.props.state.contentcategories;

    // Filter "Content Categories" by the categories found in "Custom Content"
    var firstFilterContentCategories = [];

    for (var firstArray in allContentCategories) {
      for (var firstFilter in filteredCustomCategories) {
        if (
          allContentCategories[firstArray].CategoryLabel ===
          filteredCustomCategories[firstFilter].categorylabel
        ) {
          firstFilterContentCategories.push({
            categorylabel: allContentCategories[firstArray].CategoryLabel,
            parentcategorylabel:
              allContentCategories[firstArray].ParentCategoryLabel,
            categorytitle: allContentCategories[firstArray].CategoryTitle,
            categoryimage: allContentCategories[firstArray].CategoryImage,
            categoryshortdesc:
              allContentCategories[firstArray].CategoryShortDesc,
            defaulticonname: allContentCategories[firstArray].DefaultIconName
          });
        }
      }
    }
    console.log(firstFilterContentCategories);
    // Lookup parent categories from categories found above
    var secondFilterContentCategories = [];

    for (var secondArray in allContentCategories) {
      for (var secondFilter in firstFilterContentCategories) {
        if (
          allContentCategories[secondArray].CategoryLabel ===
          firstFilterContentCategories[secondFilter].parentcategorylabel
        ) {
          secondFilterContentCategories.push({
            categorylabel: allContentCategories[secondArray].CategoryLabel,
            parentcategorylabel:
              allContentCategories[secondArray].ParentCategoryLabel,
            categorytitle: allContentCategories[secondArray].CategoryTitle,
            categoryimage: allContentCategories[secondArray].CategoryImage,
            categoryshortdesc:
              allContentCategories[secondArray].CategoryShortDesc,
            defaulticonname: allContentCategories[secondArray].DefaultIconName
          });
        }
      }
    }

    // Return unique categories found in the above
    var uniqueContentCategories = [];
    secondFilterContentCategories.forEach(function (item) {
      var i = uniqueContentCategories.findIndex(
        (x) => x.categorylabel === item.categorylabel
      );
      if (i <= -1) {
        uniqueContentCategories.push({
          categorylabel: item.categorylabel,
          categorytitle: item.categorytitle,
          categoryimage: item.categoryimage,
          categoryshortdesc: item.categoryshortdesc,
          defaulticonname: item.defaulticonname
        });
      }
    });

    // Sort the above by custom order by CategoryLabel
    var sortOrder = [
      "arrival",
      "place",
      "local-area",
      "services",
      "safety",
      "cleaning",
      "about-us"
    ];
    uniqueContentCategories.sort(function (a, b) {
      return (
        sortOrder.indexOf(a.categorylabel) - sortOrder.indexOf(b.categorylabel)
      );
    });

    return (
      <div className="canvas-with-compact-header-and-footer">
        <HeaderCompact {...this.props} MenuTitle="General Information" />
        <div id="property-info-outer-wrapper">
          <div id="property-info-inner-wrapper">
            {uniqueContentCategories.map((item) => (
              <PropertyInfoMenuItem
                key={item.categorylabel}
                {...this.props}
                CategoryLabel={item.categorylabel}
                IconLabel={item.categorylabel}
                CategoryImage={item.categoryimage}
                DetailImage={null}
                CategoryShortDesc={item.categoryshortdesc}
                FullSubCategoryLabel=""
                CategoryTitle={item.categorytitle}
                PropertyID={this.props.state.vanityPropertyId}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
