import React from "react";

function LabelIcon(props) {
  return (
    <svg
      width={612.015}
      height={612.015}
      viewBox="0 0 612.015 612.015"
      {...props}
    >
      <path d="M575.365.256H318.919c-20.227 0-23.936 8.852-43.226 29.021L23.242 280.963c-30.989 30.875-30.989 80.963 0 111.858l196.359 195.767c30.99 30.895 81.213 30.895 112.202 0l252.471-251.686c16.25-16.212 27.74-24.261 27.74-44.449V36.79C611.995 16.602 595.592.256 575.365.256zM303.739 560.619c-15.485 15.447-40.606 15.447-56.11 0L51.288 364.853c-15.485-15.447-15.485-40.492 0-55.939l28.353-28.256 251.857 252.279-27.759 27.682zm271.626-286.424c0 10.094-9.272 24.892-19.156 34.737L359.85 504.699 107.687 252.688 303.739 57.246c9.005-8.985 23.362-20.456 33.476-20.456h219.816c10.113 0 18.315 8.183 18.315 18.258v219.147h.019zM373.883 127.16c-30.99 30.895-30.99 80.964 0 111.858 30.989 30.895 81.212 30.895 112.202 0s30.99-80.964 0-111.858-81.231-30.894-112.202 0zm84.138 83.889c-15.486 15.447-40.606 15.447-56.111 0-15.485-15.447-15.485-40.492 0-55.939s40.606-15.447 56.111 0c15.503 15.448 15.503 40.492 0 55.939z" />
    </svg>
  );
}

export default LabelIcon;
