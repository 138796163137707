import React from "react";
import "../styles/CoverImage.css";

export default class CoverImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      didLoad: false
    };
  }

  onLoad = () => {
    this.setState({
      didLoad: true
    });
  };

  render() {
    const { ImageSource, PropertyTitle } = this.props;

    const OuterWrapperStyle = this.state.didLoad
      ? "cover-image-outer-wrapper"
      : "cover-image-outer-wrapper-loading";
    const InnerWrapperStyle = this.state.didLoad
      ? "cover-image-inner-wrapper"
      : "cover-image-inner-wrapper-loading";
    const ImageStyle = this.state.didLoad
      ? "cover-image"
      : "cover-image-loading cover-image-loading-animate";

    if (ImageSource) {
      return (
        <div className={OuterWrapperStyle}>
          <div className={InnerWrapperStyle}>
            <img
              className={ImageStyle}
              src={ImageSource}
              alt={PropertyTitle}
              onLoad={this.onLoad}
            />
          </div>
        </div>
      );
    }
    return <div>Awaiting Image</div>;
  }
}
