import React from "react";
import { googlePlacePhotoApiRootUrl, googleMapsApiKey } from "../constants.js";
import CoverImage from "./CoverImage";
import MapIcon from "../icons/MapIcon";
import PropertyHomeContactDetails from "./PropertyHomeContactDetails";
import InfoDetailLinkButton from "./InfoDetailLinkButton";
import InfoListItem from "./InfoListItem";
import "../styles/PropertyHome.css";

export default class PropertyHome extends React.Component {
  constructor() {
    super();
    this.redirectToGoogleMaps = this.redirectToGoogleMaps.bind(this);
  }

  redirectToGoogleMaps() {
    var googleMapsUrl =
      "http://maps.google.com/maps/place/" +
      this.props.state.propertydata.GeoLocationLat +
      "," +
      this.props.state.propertydata.GeoLocationLng;
    window.location.href = googleMapsUrl;
  }

  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    // console.log("this.state", this.props.state);
    // Return Welcome content
    const welcomeinfodetailfiltered = this.props.state.customcontent.filter(
      (DisplayContent) => {
        return DisplayContent.DetailLabel === "welcome-message";
      }
    );
    const welcomeinfodetail = welcomeinfodetailfiltered[0];

    // Filter correct content for "Essentials" section
    const displayContentEssentials = this.props.state.customcontent.filter(
      (DisplayContent) => {
        return (
          DisplayContent.FeaturedContent &&
          (DisplayContent.DetailLabel === "checking-in-and-out" ||
            DisplayContent.DetailLabel === "wifi")
        );
      }
    );

    // Sort the above alphabetically
    displayContentEssentials.sort(function (a, b) {
      var keyA = a.DetailTitle,
        keyB = b.DetailTitle;
      // Compare the 2 values
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    // Return content in correct format for "Essentials" section
    const infoDetailsEssentials = displayContentEssentials.map((a) => {
      return {
        infodetailid: a._id,
        infodetailtitle: a.DetailTitle,
        infodetailimage: a.DetailImage,
        infodetaillabel: a.DetailLabel,
        infodetailcategorylabel: a.CategoryLabel,
        infodetailsubcategorylabel: a.SubCategoryLabel
      };
    });

    // Filter correct content for "Highlights" section
    const displayContentHighlights = this.props.state.customcontent.filter(
      (DisplayContent) => {
        return (
          DisplayContent.FeaturedContent &&
          DisplayContent.DetailLabel !== "checking-in-and-out" &&
          DisplayContent.DetailLabel !== "wifi"
        );
      }
    );

    // Sort the above alphabetically
    displayContentHighlights.sort(function (a, b) {
      var keyA = a.DetailTitle,
        keyB = b.DetailTitle;
      // Compare the 2 values
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    // Return content in correct format for "Further Information" section
    const infoDetailsHighlights = displayContentHighlights.map((a) => {
      return {
        infodetailid: a._id,
        infodetailtitle: a.DetailTitle,
        infodetailimage: a.DetailImage,
        infodetaillabel: a.DetailLabel,
        infodetailcategorylabel: a.CategoryLabel,
        infodetailsubcategorylabel: a.SubCategoryLabel,
        infodetailgooglephotoref: a.GooglePhotoReference
      };
    });

    return (
      <div className="canvas-with-header-overlap-and-footer">
        <CoverImage
          ImageSource={this.props.state.propertydata.PropertyCoverImage}
          PropertyTitle={this.props.state.propertydata.PropertyTitle}
        />

        <h1 id="ph-h1">{this.props.state.propertydata.PropertyTitle}</h1>

        <p id="ph-address-text">
          {this.props.state.propertydata.PropertyDisplayAddress}
        </p>

        <button
          className="ph-mini-cta-button-round"
          onClick={this.redirectToGoogleMaps}
        >
          <MapIcon className="ph-mini-cta-button-round-icon" />
          <div className="ph-mini-cta-button-round-text"> Directions </div>
        </button>

        {welcomeinfodetail &&
          (welcomeinfodetail.DetailTextDescribe ||
            welcomeinfodetail.DetailTextLocate ||
            welcomeinfodetail.DetailTextOperate) && (
            <InfoDetailLinkButton
              {...this.props}
              Heading={"Welcome"}
              CategoryLabel={welcomeinfodetail.CategoryLabel}
              SubCategoryLabel={welcomeinfodetail.SubCategoryLabel}
              DetailLabel={welcomeinfodetail.DetailLabel}
              Image={welcomeinfodetail.DetailImage}
              PropertyID={this.props.state.vanityPropertyId}
              TopLevelPath="featured"
            />
          )}

        <div style={{ height: "15px" }}> </div>

        <div className="page-section-wrapper">
          {this.props.state.propertymgrdata && (
            <div className="ph-section-inner-wrapper">
              <div className="ph-section-heading">Your host</div>
              <div className="ph-section-content-inner-wrapper">
                <PropertyHomeContactDetails {...this.props} />
              </div>
            </div>
          )}
        </div>

        {(infoDetailsEssentials === !undefined ||
          infoDetailsEssentials.length > 0) && (
          <div className="page-section-wrapper">
            <div className="list-section-outer-wrapper">
              <div className="list-section-heading"> Essentials</div>

              <div className="list-section-inner-wrapper">
                {infoDetailsEssentials.map((item) => (
                  <InfoListItem
                    {...this.props}
                    key={item.infodetailid}
                    Heading={item.infodetailtitle}
                    CategoryLabel={item.infodetailcategorylabel}
                    SubCategoryLabel={item.infodetailsubcategorylabel}
                    DetailLabel={item.infodetaillabel}
                    DetailImage={null}
                    PropertyID={this.props.state.vanityPropertyId}
                    TopLevelPath="featured"
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {(infoDetailsHighlights === !undefined ||
          infoDetailsHighlights.length > 0) && (
          <div className="page-section-wrapper">
            <div className="list-section-outer-wrapper">
              <div className="list-section-heading"> Highlights</div>
              <div className="list-section-inner-wrapper">
                {infoDetailsHighlights.map((item) => (
                  <InfoListItem
                    {...this.props}
                    key={item.infodetailid}
                    Heading={item.infodetailtitle}
                    CategoryLabel={item.infodetailcategorylabel}
                    SubCategoryLabel={item.infodetailsubcategorylabel}
                    DetailLabel={item.infodetaillabel}
                    DetailImage={
                      item.DetailImage
                        ? item.DetailImage
                        : item.infodetailgooglephotoref
                        ? `${googlePlacePhotoApiRootUrl}maxwidth=105&photoreference=${item.infodetailgooglephotoref}&key=${googleMapsApiKey}`
                        : null
                    }
                    PropertyID={this.props.state.vanityPropertyId}
                    TopLevelPath="featured"
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
