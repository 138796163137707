import React, { Component } from "react";
import { googleMapsApiKey } from "../constants.js";

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.onScriptLoad = this.onScriptLoad.bind(this);
  }

  onScriptLoad() {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options
    );
    this.props.onMapLoad(map);
  }

  componentDidMount() {
    if (!window.google) {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = `https://maps.google.com/maps/api/js?key=${googleMapsApiKey}`;
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      //We cannot access google.maps until it's finished loading
      s.addEventListener("load", (e) => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  render() {
    return (
      <div
        id="map-container"
        style={{
          height: "calc(100vh - 64px)",
          width: "100%",
          margin: "0px",
          position: "fixed",
          top: "0px",
          bottom: "64px"
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%"
          }}
          id={this.props.id}
        />
      </div>
    );
  }
}

export default GoogleMap;
