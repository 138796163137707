import React from "react";

export default class DirectionsLinkButton extends React.Component {
  render() {
    const { ButtonLabel } = this.props;

    var googleMapsUrl =
      "http://maps.google.com/maps/place/" +
      this.props.Latitude +
      "," +
      this.props.Longitude;

    return (
      <a
        href={googleMapsUrl}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="cta-button-round-transparent">{ButtonLabel}</button>
      </a>
    );
  }
}
