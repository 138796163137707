import React from "react";
import ReactLoading from "react-loading";
import logo from "../images/airguide-logo-tm-white.png";
import "../styles/LoadingPage.css";

export default class LoadingPage extends React.Component {
  render() {
    return (
      <div id="loading-page-canvas">
        <div>
          <img src={logo} alt="logo" id="loading-logo-wrapper" />
        </div>

        <div>
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={5}
            width={100}
          />
        </div>

        <div className="loading-text">loading your guidebook...</div>
      </div>
    );
  }
}
