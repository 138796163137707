import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoadingPage from "./components/LoadingPage";
import StickyFooter from "./components/StickyFooter";
import PropertyHome from "./components/PropertyHome";
import PropertyInfoMainMenu from "./components/PropertyInfoMainMenu";
import PropertyInfoSubMenu from "./components/PropertyInfoSubMenu";
import InfoDetailMulti from "./components/InfoDetailMulti";
import InfoDetailRelatedProperty from "./components/InfoDetailRelatedProperty";
import InfoDetailPlace from "./components/InfoDetailPlace";
import BlankSpaceBottom from "./components/BlankSpaceBottom";
import PropertyMap from "./components/PropertyMap";
import OffersHome from "./components/OffersHome";
import PDFViewer from "./components/PDFViewer";
import AppHomepage from "./components/AppHomepage";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./styles/Transitions.css";
import {
  dataApiRoot,
  workflowApiRoot,
  googleAnalyticsId,
  systemId
} from "./constants.js";
import ReactGA from "react-ga";

ReactGA.initialize(googleAnalyticsId);

export default class App extends React.Component {
  state = {
    loading: true,
    vanityPropertyId: null,
    themeid: "default", // Used to identify "Theme Content" in Content Categories database
    propertydata: null
  };

  componentDidUpdate = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  componentDidMount = async () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[1];
    if (!vanityPropertyId) {
      this.setState({
        loading: false
      });
    }

    var payLoad = {
      propertyId: "",
      themeLabel: ""
    };

    //API call to return paginated data from "System Content" database
    const getSystemContentData = async function (cursor) {
      let apiUrl = `${dataApiRoot}systemcontent?constraints=[{%22key%22:%22systemid%22,%22constraint_type%22:%22equals%22,%22value%22:%22${systemId}%22}]&cursor=${cursor}`;
      var apiResults = await fetch(apiUrl).then((resp) => {
        return resp.json();
      });
      return apiResults;
    };

    const getAllSystemContentData = async function (cursor = 0) {
      const data = await getSystemContentData(cursor);
      const { remaining, results } = data.response;
      //  console.log("Retreiving data from API for cursor position: " + cursor);
      if (remaining > 0) {
        cursor += results.length;
        return results.concat(await getAllSystemContentData(cursor));
      } else {
        return results;
      }
    };

    (async () => {
      const allSystemContentData = await getAllSystemContentData();
      this.setState({
        systemcontent: allSystemContentData,
        vanityPropertyId: vanityPropertyId
      });
    })();

    // Chained API call to return property, property manager, property management company

    // 1. Return data from "Property" database filtered by vanityPropertyId and set state
    await fetch(
      `${dataApiRoot}property?constraints=[{%22key%22:%22propertyid%22,%22constraint_type%22:%22equals%22,%22value%22:%22${vanityPropertyId}%22}]`
    )
      .then((response) => response.json())
      .then((results) => {
        this.setState({
          propertydata: results.response.results[0],
          propertyId: results.response.results[0]._id
        });
        payLoad.propertyId = results.response.results[0]._id;
        payLoad.themeLabel = results.response.results[0].ThemeLabel;

        // 2. Then retun user data to check if account is active
        return fetch(`${workflowApiRoot}check-active-status`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: JSON.stringify({ userId: this.state.propertydata.LinkedUser })
        });
      })
      .then((response) => response.json())
      .then((results) => {
        this.setState({
          activeStatus: {
            userSubscriptionIsActive: results.response.userSubscriptionIsActive,
            systemSubscriptionIsActive:
              results.response.systemSubscriptionIsActive,
            propertyIsActive: this.state.propertydata.PropertyActiveStatus
          }
        });
        //     console.log("activeStatus", this.state.activeStatus);

        // 3. Then return content category data based on Theme label

        const getContentCategoryData = async function (cursor) {
          let apiUrl = `${dataApiRoot}contentcategory?constraints=[{%22key%22:%22systemid%22,%22constraint_type%22:%22equals%22,%22value%22:%22${systemId}%22}]&cursor=${cursor}`; //{%22key%22:%22themelabel%22,%22constraint_type%22:%22equals%22,%22value%22:%22${payLoad.themeLabel}%22}
          var apiResults = await fetch(apiUrl).then((resp) => {
            return resp.json();
          });
          return apiResults;
        };

        const getAllContentCategoryData = async function (cursor = 0) {
          const data = await getContentCategoryData(cursor);
          const { remaining, results } = data.response;

          //  console.log("Retreiving data from API for cursor position: " + cursor);
          if (remaining > 0) {
            cursor += results.length;
            return results.concat(await getAllContentCategoryData(cursor));
          } else {
            return results;
          }
        };

        (async () => {
          const allContentCategoryData = await getAllContentCategoryData();
          // console.log(allContentCategoryData);
          this.setState({
            contentcategories: allContentCategoryData
          });
        })();

        // 4. Then return property manager data based on linked property manager id
        return fetch(
          `${dataApiRoot}propertymanager/${this.state.propertydata.LinkedPropertyMgr}`
        );
      })
      .then((response) => response.json())
      .then((results) => {
        this.setState({
          propertymgrdata: results.response
        });

        // 5. Then return management company data based on linked property management company id
        return fetch(
          `${dataApiRoot}managementcompany/${this.state.propertydata.LinkedMgmtCo}`
        );
      })
      .then((response) => response.json())
      .then((results) => {
        this.setState({
          managementcodata: results.response
        });

        // 6. Then paginated API request to return custom content based on real property id

        const getCustomContentData = async function (cursor) {
          // let apiUrl = `${dataApiRoot}customcontent?cursor=${cursor}`;
          let apiUrl = `${dataApiRoot}customcontent?constraints=[{%22key%22:%22linkedproperty%22,%22constraint_type%22:%22contains%22,%22value%22:%22${payLoad.propertyId}%22}]&cursor=${cursor}`;
          var apiResults = await fetch(apiUrl).then((resp) => {
            return resp.json();
          });
          return apiResults;
        };

        const getAllCustomContentData = async function (cursor = 0) {
          const data = await getCustomContentData(cursor);
          const { remaining, results } = data.response;

          // console.log("Retreiving data from API for cursor position: " + cursor);
          if (remaining > 0) {
            cursor += results.length;
            return results.concat(await getAllCustomContentData(cursor));
          } else {
            return results;
          }
        };

        (async () => {
          const allCustomContentData = await getAllCustomContentData();
          // console.log(allCustomContentData);
          this.setState({
            customcontent: allCustomContentData,
            loading: false
          });
        })();
      })

      // Log any errors
      .catch((error) => {
        console.log(error);
      });

    // Set theme colours based on Property Data, then Management Co Data, fallback to system data (must have both hex's defined)

    // Extract system colour scheme from SystemContent
    const systemColours = this.state.systemcontent.filter((DisplayContent) => {
      return DisplayContent.DetailLabel === "colour-scheme";
    });

    if (
      this.state.propertydata &&
      (this.state.propertydata.PropertyHexPrimary ||
        this.state.propertydata.PropertyHexSecondary)
    ) {
      document.body.style.setProperty(
        "--theme-color-primary",
        `${this.state.propertydata.PropertyHexPrimary}`
      );
      document.body.style.setProperty(
        "--theme-color-secondary",
        `${this.state.propertydata.PropertyHexSecondary}`
      );
    }

    if (
      this.state.propertydata &&
      (!this.state.propertydata.PropertyHexPrimary ||
        !this.state.propertydata.PropertyHexSecondary)
    ) {
      document.body.style.setProperty(
        "--theme-color-primary",
        `${systemColours[0].ColourHexPrimary}`
      );
      document.body.style.setProperty(
        "--theme-color-secondary",
        `${systemColours[0].ColourHexSecondary}`
      );
    }
  };

  render() {
    console.log("this.state", this.state);

    if (this.state.propertydata === undefined) {
      return <div> No property match found </div>;
    }

    if (this.state.loading) {
      return <LoadingPage state={this.state} />;
    }

    if (
      this.state.propertydata &&
      this.state.activeStatus &&
      (!this.state.activeStatus.propertyIsActive ||
        !this.state.activeStatus.userSubscriptionIsActive ||
        !this.state.activeStatus.systemSubscriptionIsActive)
    ) {
      return (
        <div>
          This guide book is not currently available, please contact your host
          who will be able to activate it.
        </div>
      );
    }

    return (
      <Router>
        <>
          <Route
            exact
            path="/"
            render={(props) => <AppHomepage {...props} state={this.state} />}
          />

          {this.state.propertydata &&
            this.state.activeStatus &&
            this.state.activeStatus.propertyIsActive &&
            this.state.activeStatus.userSubscriptionIsActive &&
            this.state.activeStatus.systemSubscriptionIsActive && (
              <>
                <Route
                  exact
                  path={[
                    "/:vanityPropertyId",
                    "/:vanityPropertyId/info",
                    "/:vanityPropertyId/map",
                    "/:vanityPropertyId/cleaning",
                    "/:vanityPropertyId/offers",
                    "/:vanityPropertyId/nearby/*",
                    "/:vanityPropertyId/offers/other-places/*",
                    "/:vanityPropertyId/offers/local/details/*",
                    "/:vanityPropertyId/info/:categorylabel",
                    "/:vanityPropertyId/info/:categorylabel/:subcategorylabel",
                    "/:vanityPropertyId/featured/:categorylabel/:detaillabel",
                    "/:vanityPropertyId/info/:categorylabel/:subcategorylabel/:detaillabel",
                    "/:vanityPropertyId/featured/:categorylabel/:subcategorylabel/:detaillabel"
                  ]}
                  render={(props) => (
                    <>
                      <StickyFooter {...props} state={this.state} />
                    </>
                  )}
                />

                <Route
                  exact
                  path="/loading"
                  render={(props) => (
                    <LoadingPage {...props} state={this.state} />
                  )}
                />

                <Route
                  render={({ location }) => (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={100}
                      >
                        <Route
                          exact
                          path="/:vanityPropertyId"
                          render={(props) => (
                            <div>
                              <PropertyHome {...props} state={this.state} />
                              <BlankSpaceBottom />
                            </div>
                          )}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                />

                <Route
                  render={({ location }) => (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={100}
                      >
                        <Route
                          exact
                          path="/:vanityPropertyId/info"
                          render={(props) => (
                            <div>
                              <PropertyInfoMainMenu
                                {...props}
                                state={this.state}
                              />
                              <BlankSpaceBottom />
                            </div>
                          )}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                />

                <Route
                  exact
                  path="/:vanityPropertyId/map"
                  render={(props) => (
                    <>
                      <PropertyMap {...props} state={this.state} />
                      <BlankSpaceBottom />
                    </>
                  )}
                />

                <Route
                  render={({ location }) => (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={100}
                      >
                        <Route
                          exact
                          path="/:vanityPropertyId/offers"
                          render={(props) => (
                            <div>
                              <OffersHome {...props} state={this.state} />
                              <BlankSpaceBottom />
                            </div>
                          )}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                />

                <Route
                  render={({ location }) => (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={100}
                      >
                        <Switch>
                          <Route
                            exact
                            path="/:vanityPropertyId/info/:categorylabel"
                            render={(props) => (
                              <div>
                                <PropertyInfoSubMenu
                                  {...props}
                                  state={this.state}
                                />
                                <BlankSpaceBottom />
                              </div>
                            )}
                          />
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                />

                <Route
                  render={({ location }) => (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={100}
                      >
                        <Route
                          exact
                          path="/:vanityPropertyId/nearby/*"
                          render={(props) => (
                            <div>
                              <InfoDetailPlace {...props} state={this.state} />
                              <BlankSpaceBottom />
                            </div>
                          )}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                />

                <Route
                  render={({ location }) => (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={100}
                      >
                        <Route
                          exact
                          path="/:vanityPropertyId/docviewer"
                          render={(props) => (
                            <div>
                              <PDFViewer {...props} state={this.state} />
                              <BlankSpaceBottom />
                            </div>
                          )}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                />

                <Route
                  render={({ location }) => (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={100}
                      >
                        <Route
                          exact
                          path={[
                            "/:vanityPropertyId/featured/:categorylabel/:detaillabel",
                            "/:vanityPropertyId/info/:categorylabel/:subcategorylabel/:detaillabel"
                          ]}
                          render={(props) => (
                            <div>
                              <InfoDetailMulti {...props} state={this.state} />
                              <BlankSpaceBottom />
                            </div>
                          )}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                />

                <Route
                  render={({ location }) => (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={100}
                      >
                        <Route
                          exact
                          path="/:vanityPropertyId/offers/other-places/*"
                          render={(props) => (
                            <div>
                              <InfoDetailRelatedProperty
                                {...props}
                                state={this.state}
                              />
                              <BlankSpaceBottom />
                            </div>
                          )}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                />

                <Route
                  render={({ location }) => (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={100}
                      >
                        <Route
                          exact
                          path="/:vanityPropertyId/offers/local/details/*"
                          render={(props) => (
                            <div>
                              <InfoDetailMulti {...props} state={this.state} />
                              <BlankSpaceBottom />
                            </div>
                          )}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                />

                <Route
                  render={({ location }) => (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={100}
                      >
                        <Route
                          exact
                          path="/:vanityPropertyId/info/:categorylabel/:subcategorylabel"
                          render={(props) => (
                            <div>
                              <InfoDetailMulti {...props} state={this.state} />
                              <BlankSpaceBottom />
                            </div>
                          )}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                />
              </>
            )}
        </>
      </Router>
    );
  }
}
