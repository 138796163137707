import React from "react";

function HomeIcon(props) {
  return (
    <svg width={652.43} height={652.43} viewBox="0 0 652.43 652.43" {...props}>
      <path d="M326.225 0L20.215 359.998l28.707 28.83 73.418-83.001v265.05c0 45.037 37.209 81.553 82.064 81.553h243.642c44.854 0 82.063-36.516 82.063-81.553v-265.05l73.398 83.001 28.707-28.83L326.225 0zM488.64 570.877c0 22.529-18.187 40.777-40.614 40.777H204.404c-22.428 0-41.287-18.27-41.287-40.777V257.404L326.225 59.738l163.108 197.034-.693 314.105z" />
    </svg>
  );
}

export default HomeIcon;
