import React from "react";
import CrossIcon from "../icons/CrossIcon";

export default class HeaderCloseButton extends React.Component {
  constructor() {
    super();

    this.handleBack = this.handleBack.bind(this);
  }

  handleBack() {
    this.props.history.goBack();
  }

  render() {
    if (
      this.props.match.path === "/:vanityPropertyId/info" ||
      this.props.match.path === "/:vanityPropertyId/info/:categorylabel"
    ) {
      return (
        <button className="header-compact-button" onClick={this.handleBack}>
          <div className="header-compact-button-icon-wrapper">
            <CrossIcon className="header-compact-button-icon" />
          </div>
        </button>
      );
    }

    return (
      <>
        <div> </div>
        <button className="header-button" onClick={this.handleBack}>
          <div> </div>
          <div className="header-button-icon-wrapper">
            <CrossIcon className="header-button-icon" />
          </div>
        </button>
      </>
    );
  }
}
