import React from "react";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";

export default class HeaderBackButton extends React.Component {
  constructor() {
    super();

    this.handleBack = this.handleBack.bind(this);
  }

  handleBack() {
    this.props.history.goBack();
  }

  render() {
    if (this.props.match.path === "/:vanityPropertyId/info") {
      return <div style={{ width: "50px" }}></div>;
    }

    if (this.props.match.path === "/:vanityPropertyId/info/:categorylabel") {
      return (
        <button className="header-compact-button" onClick={this.handleBack}>
          <div className="header-compact-button-icon-wrapper">
            <ChevronLeftIcon className="header-compact-button-icon" />
          </div>
        </button>
      );
    }

    if (
      this.props.match.path === "/:vanityPropertyId/cleaningreport/*" ||
      this.props.match.path === "/:vanityPropertyId/latestcleaningreport/*" ||
      this.props.match.path === "/:vanityPropertyId/docviewer"
    ) {
      return (
        <button className="header-compact-button" onClick={this.handleBack}>
          <div className="header-compact-button-icon-wrapper">
            <ChevronLeftIcon className="header-compact-button-icon" />
          </div>
        </button>
      );
    }

    return (
      <button className="header-button" onClick={this.handleBack}>
        <div className="header-button-icon-wrapper">
          <ChevronLeftIcon className="header-button-icon" />
        </div>
      </button>
    );
  }
}
