import React from "react";
import { NavLink } from "react-router-dom";
import HomeIcon from "../icons/HomeIcon";
import MapIcon from "../icons/MapIcon";
import ListMenuIcon from "../icons/ListMenuIcon";
import LabelIcon from "../icons/LabelIcon";
import "../styles/StickyFooter.css";

export default class StickyFooter extends React.Component {
  render() {
    const vanityPropertyId = window.location.pathname.split("/")[1];
    const marketingActiveStatus = this.props.state.propertydata
      .MarketingActiveStatus;

    if (!marketingActiveStatus) {
      document.body.style.setProperty("--footer-navlink-width", "33%");
    }

    if (marketingActiveStatus) {
      document.body.style.setProperty("--footer-navlink-width", "25%");
    }

    // If url matches "/vanityPropertyId" set home navlink class to "active"
    if (
      this.props.match.path === "/:vanityPropertyId" ||
      this.props.match.path ===
        "/:vanityPropertyId/featured/:categorylabel/:detaillabel"
    ) {
      return (
        <footer className="sticky-footer-outer">
          <div className="sticky-footer-inner">
            <NavLink
              exact
              to={"/" + vanityPropertyId}
              className="sticky-footer-navlink-active"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <HomeIcon className="sticky-footer-icon-active" />
                <div className="sticky-footer-textbox">Home</div>
              </div>
            </NavLink>

            <NavLink
              exact
              to={"/" + vanityPropertyId + "/info"}
              className="sticky-footer-navlink"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <ListMenuIcon className="sticky-footer-icon" />
                <div className="sticky-footer-textbox">Info</div>
              </div>
            </NavLink>

            <NavLink
              exact
              to={"/" + vanityPropertyId + "/map"}
              className="sticky-footer-navlink"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <MapIcon className="sticky-footer-icon" />
                <div className="sticky-footer-textbox">Nearby</div>
              </div>
            </NavLink>

            {marketingActiveStatus && (
              <NavLink
                exact
                to={"/" + vanityPropertyId + "/offers"}
                className="sticky-footer-navlink"
              >
                <div className="sticky-footer-icon-text-wrapper">
                  <LabelIcon className="sticky-footer-icon" />
                  <div className="sticky-footer-textbox">Offers</div>
                </div>
              </NavLink>
            )}
          </div>
        </footer>
      );
    }

    // If url matches "info" set info navlink class to "active"
    if (
      (this.props.match.path === "/:vanityPropertyId/info" ||
        this.props.match.path === "/:vanityPropertyId/info/:categorylabel" ||
        this.props.match.path ===
          "/:vanityPropertyId/info/:categorylabel/:subcategorylabel") &
      (this.props.match.path !== "/:vanityPropertyId/offers" ||
        this.props.match.path !== "/:vanityPropertyId/offers/*")
    ) {
      return (
        <footer className="sticky-footer-outer">
          <div className="sticky-footer-inner">
            <NavLink
              exact
              to={"/" + vanityPropertyId}
              className="sticky-footer-navlink"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <HomeIcon className="sticky-footer-icon" />
                <div className="sticky-footer-textbox">Home</div>
              </div>
            </NavLink>

            <NavLink
              exact
              to={"/" + vanityPropertyId + "/info"}
              className="sticky-footer-navlink-active"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <ListMenuIcon className="sticky-footer-icon-active" />
                <div className="sticky-footer-textbox">Info</div>
              </div>
            </NavLink>

            <NavLink
              exact
              to={"/" + vanityPropertyId + "/map"}
              className="sticky-footer-navlink"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <MapIcon className="sticky-footer-icon" />
                <div className="sticky-footer-textbox">Nearby</div>
              </div>
            </NavLink>

            {marketingActiveStatus && (
              <NavLink
                exact
                to={"/" + vanityPropertyId + "/offers"}
                className="sticky-footer-navlink"
              >
                <div className="sticky-footer-icon-text-wrapper">
                  <LabelIcon className="sticky-footer-icon" />
                  <div className="sticky-footer-textbox">Offers</div>
                </div>
              </NavLink>
            )}
          </div>
        </footer>
      );
    }

    // If url matches "/map" set map navlink class to "active"
    if (
      this.props.match.path === "/:vanityPropertyId/map" ||
      this.props.match.path === "/:vanityPropertyId/nearby/*"
    ) {
      return (
        <footer className="sticky-footer-outer">
          <div className="sticky-footer-inner">
            <NavLink
              exact
              to={"/" + vanityPropertyId}
              className="sticky-footer-navlink"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <HomeIcon className="sticky-footer-icon" />
                <div className="sticky-footer-textbox">Home</div>
              </div>
            </NavLink>

            <NavLink
              exact
              to={"/" + vanityPropertyId + "/info"}
              className="sticky-footer-navlink"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <ListMenuIcon className="sticky-footer-icon" />
                <div className="sticky-footer-textbox">Info</div>
              </div>
            </NavLink>

            <NavLink
              exact
              to={"/" + vanityPropertyId + "/map"}
              className="sticky-footer-navlink-active"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <MapIcon className="sticky-footer-icon-active" />
                <div className="sticky-footer-textbox">Nearby</div>
              </div>
            </NavLink>

            {marketingActiveStatus && (
              <NavLink
                exact
                to={"/" + vanityPropertyId + "/offers"}
                className="sticky-footer-navlink"
              >
                <div className="sticky-footer-icon-text-wrapper">
                  <LabelIcon className="sticky-footer-icon" />
                  <div className="sticky-footer-textbox">Offers</div>
                </div>
              </NavLink>
            )}
          </div>
        </footer>
      );
    }

    // If url matches "cleaningreport or cleaning" set info navlink class to "active"
    if (
      this.props.match.path === "/:vanityPropertyId/offers" ||
      this.props.match.path === "/:vanityPropertyId/offers/other-places/*" ||
      this.props.match.path === "/:vanityPropertyId/offers/local/details/*" ||
      this.props.match.params.categorylabel === "offers"
    ) {
      return (
        <footer className="sticky-footer-outer">
          <div className="sticky-footer-inner">
            <NavLink
              exact
              to={"/" + vanityPropertyId}
              className="sticky-footer-navlink"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <HomeIcon className="sticky-footer-icon" />
                <div className="sticky-footer-textbox">Home</div>
              </div>
            </NavLink>

            <NavLink
              exact
              to={"/" + vanityPropertyId + "/info"}
              className="sticky-footer-navlink"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <ListMenuIcon className="sticky-footer-icon" />
                <div className="sticky-footer-textbox">Info</div>
              </div>
            </NavLink>

            <NavLink
              exact
              to={"/" + vanityPropertyId + "/map"}
              className="sticky-footer-navlink"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <MapIcon className="sticky-footer-icon" />
                <div className="sticky-footer-textbox">Nearby</div>
              </div>
            </NavLink>

            {marketingActiveStatus && (
              <NavLink
                exact
                to={"/" + vanityPropertyId + "/offers"}
                className="sticky-footer-navlink-active"
              >
                <div className="sticky-footer-icon-text-wrapper">
                  <LabelIcon className="sticky-footer-icon-active" />
                  <div className="sticky-footer-textbox">Offers</div>
                </div>
              </NavLink>
            )}
          </div>
        </footer>
      );
    }

    // If none of above conditions are matched
    return (
      <footer className="sticky-footer-outer">
        <div className="sticky-footer-inner">
          <NavLink
            exact
            to={"/" + vanityPropertyId}
            className="sticky-footer-navlink"
          >
            <div className="sticky-footer-icon-text-wrapper">
              <HomeIcon className="sticky-footer-icon" />
              <div className="sticky-footer-textbox">Home</div>
            </div>
          </NavLink>

          <NavLink
            exact
            to={"/" + vanityPropertyId + "/info"}
            className="sticky-footer-navlink"
          >
            <div className="sticky-footer-icon-text-wrapper">
              <ListMenuIcon className="sticky-footer-icon" />
              <div className="sticky-footer-textbox">Info</div>
            </div>
          </NavLink>

          <NavLink
            exact
            to={"/" + vanityPropertyId + "/map"}
            className="sticky-footer-navlink"
          >
            <div className="sticky-footer-icon-text-wrapper">
              <MapIcon className="sticky-footer-icon" />
              <div className="sticky-footer-textbox">Nearby</div>
            </div>
          </NavLink>

          {marketingActiveStatus && (
            <NavLink
              exact
              to={"/" + vanityPropertyId + "/offers"}
              className="sticky-footer-navlink"
            >
              <div className="sticky-footer-icon-text-wrapper">
                <LabelIcon className="sticky-footer-icon" />
                <div className="sticky-footer-textbox">Offers</div>
              </div>
            </NavLink>
          )}
        </div>
      </footer>
    );
  }
}
