import React from "react";
import PropertyInfoMenuImage from "./PropertyInfoMenuImage";
import ChevronRightIcon from "../icons/ChevronRightIcon";

export default class PropertyInfoMenuItem extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  // Redirect to "Info Detail Multi" page
  handleClick = (e) => {
    this.setState({ categorylabel: e.target.id }, () => {
      this.props.history.push(
        "/" +
          this.props.PropertyID +
          "/info/" +
          this.props.CategoryLabel +
          this.props.FullSubCategoryLabel
      );
    });
  };

  render() {
    const {
      CategoryTitle,
      CategoryImage,
      CategoryShortDesc,
      CategoryLabel
    } = this.props;

    return (
      <div className="info-list-item" onClick={this.handleClick}>
        <div className="info-list-item-image-and-content">
          <PropertyInfoMenuImage
            CategoryImage={CategoryImage}
            DetailImage={null}
            AltText={CategoryLabel}
          />
          <div>
            <div className="info-list-item-heading">{CategoryTitle}</div>
            <div className="info-list-item-subheading">{CategoryShortDesc}</div>
          </div>
        </div>
        <div className="info-list-item-chevron-icon-wrapper">
          <ChevronRightIcon className="info-list-item-chevron-icon" />
        </div>
      </div>
    );
  }
}
