import React from "react";

function ChevronLeftIcon(props) {
  return (
    <svg
      width={791.966}
      height={791.967}
      viewBox="0 0 791.966 791.967"
      {...props}
    >
      <path d="M245.454 396.017L617.077 56.579c12.973-12.94 12.973-33.934 0-46.874-12.973-12.94-34.033-12.94-47.006 0L174.615 370.896c-6.932 6.899-9.87 16.076-9.408 25.087-.462 9.045 2.476 18.188 9.408 25.088l395.456 361.19c12.973 12.94 34.033 12.94 47.006 0 12.973-12.939 12.973-33.934 0-46.873L245.454 396.017z" />
    </svg>
  );
}

export default ChevronLeftIcon;
