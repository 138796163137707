import React from "react";
import InfoDetailHeading from "./InfoDetailHeading";
import InfoDetailParagraph from "./InfoDetailParagraph";
import WebLinkButton from "./WebLinkButton";
import VideoPlayer from "./VideoPlayer";
import Header from "./Header";
import "../styles/InfoDetail.css";

export default class InfoDetailRelatedProperty extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    // Use final part of pathname to set Detail Label
    var pathname = window.location.pathname;
    var pathparams = pathname.substring(pathname.lastIndexOf("/") + 1);
    const infodetaillabel = pathparams;

    // Use Detail label to lookup custom content
    const displaycontent = this.props.state.relatedproperties.filter(
      (DisplayContent) => {
        return DisplayContent.PropertyID === infodetaillabel;
      }
    );

    // Display content on page
    return (
      <div className="info-detail-canvas">
        <Header {...this.props} />
        {displaycontent.map((item) => (
          <div key={item._id}>
            <VideoPlayer
              DetailVideo={item.DetailVideo}
              DetailImage={item.DetailImage}
              CategoryImage={null} // temporarily null until fixed
              DetailLabel={item.DetailLabel}
              FirstDetailLabel={null} // temporarily null until fixed
              SystemContent={this.props.state.systemcontent}
            />

            <InfoDetailHeading
              DetailLabel={item.DetailLabel}
              DetailTitle={item.MarketingTitle}
              SystemContent={this.props.state.systemcontent}
            />

            <h2 className="info-detail-h2"> {item.MarketingStrapline} </h2>

            <InfoDetailParagraph
              DetailType={"describe"}
              DetailLabel={item.DetailLabel}
              DetailText={item.MarketingShortDesc}
            />

            {item.BookingUrl && (
              <WebLinkButton
                {...this.props}
                ButtonLabel="Book now"
                WebLink={item.BookingUrl}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}
